import Login from './Login';
import Auth from './Auth';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRoutes';

import Dashboard from './Dashboard';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DemoWidget from './DemoWidget';
import DemoPreview from './DemoPreview';
import DemoEdit from './DemoEdit';
import DemoList from './DemoList';
import UserList from './UserList';
import Customize from './Customize';
import useInitializeGaTracker from './useInitializeGaTracker';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      staleTime: 300000,
    },
  },
});

function App() {
  useInitializeGaTracker();

  return (
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<Dashboard />}>
                <Route element={<DemoList />} path="/" />
                <Route element={<UserList />} path="/users" />
                <Route element={<Customize />} path="/customize" />
              </Route>
              <Route element={<DemoEdit />} path="/demos/:token/edit" />
            </Route>
            <Route element={<Login />} path="/login" />
            <Route element={<Auth />} path="/auth" />
            <Route element={<DemoWidget />} path="/:token" />
            <Route element={<DemoPreview />} path="/demos/:token" />
          </Routes>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
